import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
// import HoneybadgerVue from "@honeybadger-io/vue";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import VueMobileDetection from "vue-mobile-detection";
require("./assets/main.css");

import "vue3-toastify/dist/index.css";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { createPinia } from "pinia";
import VueAnalytics from "vue-analytics";

const app = createApp(App);

app.use(store);
app.use(router);
app.use(BootstrapVue);
app.use(BootstrapVueIcons);
// app.use(HoneybadgerVue, config);
app.use(VueMobileDetection);
app.component("font-awesome-icon", FontAwesomeIcon);
app.use(createPinia());

app.use(VueAnalytics, {
  id: "G-R32D6PGNJQ",
  autoTracking: {
    exception: true,
  },
});

app.mount("#app");
